import React, {useEffect, useState, useCallback} from 'react'
import { withRouter } from "react-router"
import { Modal, Button, Icon, Input,
         Header, Card, Label } from 'semantic-ui-react'
import { _read, _create } from '../../Lib/canvas'
import Cookies from 'js-cookie'
import { _addCollectionToStudy, _getSimilarByInImg } from '../../Lib/canvas'
import Dropzone from 'react-dropzone';
import {API_URL} from '../../config'


function UploadImage(){
    const token = JSON.parse(Cookies.get("deepstain-access")).token;
    const [temp_study, setTempStudy] = useState();
    const [studies, setStudies] = useState();
    const [temp_in_img, setTempInImg] = useState();
    const [collectionToUploadTo, setCollectionToUploadTo] = useState();
    const [possibleCollections, setPossibleCollections] = useState();
    const [uploadImageOpen, setUploadImageOpen] = useState();
    const [loading, setLoading] = useState();

    const getStudies = useCallback(async () => {
        const s = await _read(token, "studies")
        const res = s
        if (res.length === 0)
          return;
        const sOptions = []
        for (let i = 0; i < res.length; i++){
          const mn = res[i].name
          const mid = res[i].id
          const t = {
            key: mn,
            text: mn,
            value: mid
          }
          sOptions.push(t)
        }
        setStudies(sOptions);
    }, [token])

    const createCollection = async (name) => {
        const r = await _create(token, {name: name}, "collections")
        const coll_body = await r.json();
        return coll_body.obj;
    }

    const createDryPrediction = async (collection, name, in_img) => {
        const r = await _create(
            token, {
                name: name,
                in_img: in_img === undefined ? Date.now().toString(36) + 
                        Math.random().toString(36).substring(2) : in_img,
                model:  Date.now().toString(36) + 
                        Math.random().toString(36).substring(2),
                collection: collection
            }, "predictions/dry")
        const pred_body = await r.json()
        return pred_body.obj
    }

    const getPresignedURL = async (token, slide_name, mediaType) => {
        const url = `${API_URL}/v1/predictions/upload`
        const bearer = `Bearer ${token}`;
        const j = {
            id: slide_name, 
            mediaType: mediaType
        }
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json', 
                'Authorization': bearer
            },
            body: JSON.stringify(j)
        });
        const json = await response.json()
        return json.url
    }

    const uploadToS3 = async (file, id) => {
        setLoading(true);        
        const name = file.name
        const url = await getPresignedURL(
            token, id, name.split('.').pop())
        await fetch(url, {
            method: "PUT",
            body: file,
            headers: {
                'Content-Type': ''
            },
        });
    }

    const uploadToOrthanc = async (id, mediaType) => {
        const url = `${API_URL}/v1/predictions/ort`
        const bearer = `Bearer ${token}`;
        const j = {
            id: id, 
            mediaType: mediaType
        }
        await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json', 
                'Authorization': bearer
            },
            body: JSON.stringify(j)
        });
        setLoading(false);
        setUploadImageOpen(false);
    }

    const handleUpload = async (acceptedFiles) => {
        setLoading(true);
        let coll_body = {};
        for(const file of acceptedFiles) {
            if(collectionToUploadTo !== undefined) {
                coll_body.id = collectionToUploadTo;
            } else {
                const nuCollection = await createCollection(file.name);
                coll_body.id = nuCollection.id;
            }

            const pred_body = await createDryPrediction(
                coll_body.id, file.name, temp_in_img);
            await _addCollectionToStudy(token, {
                collectionId: coll_body.id, 
                studyId: temp_study
            });
            await uploadToS3(file, pred_body.id);
            await uploadToOrthanc(pred_body.id, file.name.split('.').pop());
        }
        setLoading(false);
        setUploadImageOpen(false);
    }

    useEffect(() => {
        getStudies();
    }, [getStudies])

    Array.prototype.removeByIndex = function(i) {
        if(!Number.isInteger(i) || i < 0) {
            // i must be an integer
            return this;
        }
        return this.filter((f, indx) => indx !== i)
    }

    return <div style={{padding: '2vh'}}>
        <Modal
            size="large"
            onClose={() => !loading && setUploadImageOpen(false)}
            onOpen={() => setUploadImageOpen(true)}
            open={uploadImageOpen}
            trigger={
                <Button fluid color="orange" size="massive" icon labelPosition='left'>
                    Upload Image
                    <Icon name='pencil' />
                </Button>}>
            <div style={{padding: '3vh', minWidth: '80vh'}}>
                <div>
                    <Header>Studies</Header>
                    <Card.Group>
                        {studies && studies.map(
                            (study) => <Card 
                                    style={{backgroundColor: temp_study === study.value ? "#6982A5" : ""}} 
                                    onClick={() => {
                                        setTempStudy(study.value)
                                    }}>
                                    <Card.Content>
                                        <Card.Header>{study.text}</Card.Header>
                                    </Card.Content>
                                </Card>)}
                    </Card.Group>

                    <Header>Input Image ID</Header>
                    <Input
                        placeholder='check out slide ids in the slide section'
                        onChange={async (e, {value}) => {
                            const r = await _getSimilarByInImg(value, token, temp_study);
                            const pc = r.choices.map((prediction) => {
                                return {
                                    collection_id: prediction.collection_id, 
                                    collection_name: prediction.collection_name
                                };
                            })
                            setPossibleCollections(pc);
                            setTempInImg(value);}}/>

                    { possibleCollections && possibleCollections.length > 0 && 
                        <Card.Group>
                        {possibleCollections && possibleCollections.map(
                            (collection) => <Card 
                                    style={{backgroundColor: collectionToUploadTo === collection.collection_id ? "#6982A5" : ""}} 
                                    onClick={() => {setCollectionToUploadTo(collection.collection_id)}}>
                                    <Card.Content>
                                        <Card.Header>{collection.collection_name}</Card.Header>
                                        <Label ribbon>{collection.collection_id}</Label>
                                    </Card.Content>
                                </Card>)}
                        </Card.Group>
                    }

                    {!loading && <Dropzone style={{padding: '1vh'}} onDrop={handleUpload}>
                        {({getRootProps, getInputProps, isDragActive}) => (
                            <section>
                                <div style={{padding: '1vh', height: '20vh', backgroundColor: 'grey'}} {...getRootProps()}>
                                    <input {...getInputProps()} />
                                        <div style={{paddingTop: '5vh'}}>
                                            {isDragActive ? 
                                            <Header style={{textAlign: 'center'}}>
                                                Release your slides anywhere to begin.
                                            </Header> :
                                            <Header style={{textAlign: 'center'}}>
                                                Drop your slides anywhere to begin.
                                            </Header>}
                                        </div>
                                </div>
                            </section>
                        )}
                    </Dropzone>}

                    {loading && <Header>Uploading your slides...</Header>}                    
                </div>
            </div>
        </Modal>
    </div>
}




   
export default withRouter(UploadImage)
import React from 'react'
import { withRouter } from "react-router"
import { Modal, Button, Icon,
         Header, Input, Dropdown } from 'semantic-ui-react'
import Cookies from 'js-cookie'
import {API_URL, FRONTEND_API_URL} from "../../config";

const roles = [
  { key: "basic", text: "basic", value: "basic"},
  { key: "uploader", text: "uploader", value: "uploader"},
  { key: "viewer", text: "viewer", value: "viewer"},
  { key: "manager", text: "manager", value: "manager"},
]

class Invite extends React.Component {
    constructor (props) {
        super(props)
        this.state = { 
            token: JSON.parse(
                Cookies.get("deepstain-access")).token,
            email: undefined, 
            link: undefined
        }
    }

    createInviteLink =  async () => {
      const {token, email, role} = this.state;
      const url = `${API_URL}/v1/auth/signup-token`
        const bearer = `Bearer ${token}`;
        const j = {
            email: email, 
            role: role, 
            expires: 5
        }
        const r = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json', 
                'Authorization': bearer
            },
            body: JSON.stringify(j)
        });

        const rj = await r.json()
        const tt = await rj.signup_token
        this.setState({link: `${FRONTEND_API_URL}/signup?email=${email}&token=${tt}`})
    }

    render () {
      const {createInviteLinkOpen, link} = this.state;
      return <div style={{padding: '2vh'}}>          
        <Modal
            size="large"
            onClose={() => this.setState({createInviteLinkOpen: false})}
            onOpen={() => this.setState({createInviteLinkOpen: true})}
            open={createInviteLinkOpen}
            trigger={
                <Button fluid color="teal" size="massive" icon labelPosition='left'>
                    Invite to Canvas
                    <Icon name='user' />
                </Button>}>
            <Modal.Content>
            <Header>Email</Header>
            <Input onChange={(e, s) => {
                    this.setState({email: s.value})}} 
                  fluid 
                  size="big"
                  placeholder={`Email`} />
            <Header>Role</Header>
            <Dropdown
              placeholder='Collection of models to stain with.'
              size="big"
              fluid
              selection
              options={roles}
              onChange={(e, d) => {
                  this.setState({role: d.value})}}/>
            <Header>Your Link: <a href={link}>{link ? link : ""}</a></Header>
            </Modal.Content>

            <Modal.Actions>
                <Button                 
                    content="Cancel"
                    labelPosition='right'
                    icon='times' color='red' 
                    onClick={() => this.setState({
                    createInviteLinkOpen: false})}>
                </Button>
                <Button
                    content="Create"
                    labelPosition='right'
                    icon='checkmark'
                    onClick={this.createInviteLink}
                    positive
                />
            </Modal.Actions>
        </Modal>
      </div>
    }
  }

export default withRouter(Invite)